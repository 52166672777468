<template>
  <section id="dashboard-ecommerce">
    <b-row class="match-height">
      <b-col
        sm="12"
        md="12"
        lg="6"
        xl="7"
      >
        <ecommerce-statistics :data="data.statisticsItems" />
      </b-col>
      <b-col
        sm="12"
        md="12"
        lg="6"
        xl="5"
      >
        <ecommerce-profit-Line :data="data.staticAcess" />
        <ecommerce-order-chart :data="data.statisticsOrder" />
      </b-col>
      <!--/ Bar Chart - Orders -->
      <b-col
        sm="12"
        md="12"
        lg="5"
        xl="4"
      >
        <ecommerce-profit-chart :data="data.statisticsProfit" />
      </b-col>
      <b-col
        sm="12"
        md="12"
        lg="7"
        xl="8"
      >
        <ecommerce-statistics :data="data.statisticsItemsTime" />
      </b-col>
      <!-- Browser States Card -->
    </b-row>

    <b-row>
      <b-col cols="12">
        <invoice-list />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'

// import { getUserData } from '@/auth/utils'
import EcommerceStatistics from '../../../components/dashboard/EcommerceStatistics.vue'
import EcommerceOrderChart from '../../../components/dashboard/EcommerceOrderChart.vue'
import EcommerceProfitChart from '../../../components/dashboard/EcommerceProfitChart.vue'
import EcommerceProfitLine from '../../../components/dashboard/EcommerceProfitLine.vue'
import EcommerceBrowserStates from '../../../components/dashboard/EcommerceBrowserStates.vue'
import InvoiceList from '../../../invoice/invoice-list/InvoiceList.vue'
import { mapActions } from 'vuex'
import Vue from 'vue'

export default {
  components: {
    BRow,
    BCol,
    EcommerceStatistics,
    EcommerceOrderChart,
    EcommerceProfitChart,
    InvoiceList,
    // EcommerceBrowserStates,
    EcommerceProfitLine
  },
  data() {
    return {
      data: {
        staticAcess: {
          access: 0,
          series: [
            {
              name: 'Quarta',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
            {
              name: 'Sabado',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
        },
        statisticsItemsTime: [
          {
            coloricon: '#5193d1', icon: 'ClockIcon', color: 'light-info', title: 'Quantidade de horas de aulas assistidas', subtitle: ''
          },
          {
            coloricon: '#7C70F1', icon: 'InfoIcon', color: 'light-primary', title: 'Quantidade de atividades', subtitle: ''
          },
        ],
        statisticsItems: [
          {
            coloricon: '#7C70F1', icon: 'ActivityIcon', color: 'light-primary', title: ' ', subtitle: 'Numero de inscritos totais'
          },
          {
            coloricon: '#5193d1', icon: 'BriefcaseIcon', color: 'light-info', title: ' ', subtitle: 'Residentes'
          },
          {
            coloricon: '#29C770', icon: 'HeartIcon', color: 'light-success', title: ' ', subtitle: 'Médicos'
          },
          {
            coloricon: '#7C70F1', icon: 'BookIcon', color: 'light-primary', title: ' ', subtitle: 'Estudantes acadêmico'
          },
          {
            coloricon: '#5193d1', icon: 'ShoppingBagIcon', color: 'light-info', title: ' ', subtitle: 'Promocional'
          },
          {
            coloricon: '#29C770', icon: 'ToolIcon', color: 'light-success', title: ' ', subtitle: 'Outros profissionais'
          },
          {
            coloricon: '#7C70F1', icon: 'VideoIcon', color: 'light-primary', title: ' ', subtitle: 'Palestrantes'
          },
          {
            coloricon: '#5193d1', icon: 'Edit2Icon', color: 'light-info', title: ' ', subtitle: 'Fellow'
          },
          {
            coloricon: '#29C770', icon: 'ArchiveIcon', color: 'light-success', title: ' ', subtitle: 'Administração SBAO'
          },
          {
            coloricon: '#7C70F1', icon: 'UserIcon', color: 'light-primary', title: ' ', subtitle: 'Acima dos 70'
          },
          {
            coloricon: '#5193d1', icon: 'UserPlusIcon', color: 'light-info', title: ' ', subtitle: 'Patrocinadores'
          },
          {
            coloricon: '#EE7778', icon: 'AlertTriangleIcon', color: 'light-danger', title: ' ', subtitle: 'Cancelados'

          },
        ],
        statisticsOrder: {
          series: [
            {
              name: 0,
              total: 0,
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
            },
          ],
        },
        statisticsProfit: {
          amount: 0,
          series: [
            {
              name: '2020',
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
            },
          ],
        },
        earningsChart: {
          series: [53, 16, 31],
        },
        revenue: {
          years: ['2020', '2019', '2018'],
          price: '25,852',
          budget: '56,800',
          revenueReport: {
            series: [
              {
                name: 'Earning',
                data: [95, 177, 284, 256, 105, 63, 168, 218, 72],
              },
              {
                name: 'Expense',
                data: [-145, -80, -60, -180, -100, -60, -85, -75, -100],
              },
            ],
          },
          budgetChart: {
            series: [
              {
                data: [61, 48, 69, 52, 60, 40, 79, 60, 59, 43, 62],
              },
              {
                data: [20, 10, 30, 15, 23, 0, 25, 15, 20, 5, 27],
              },
            ],
          },
        },
      }
    }
  },
  computed: {
    statistics() {
      return this.$store.state.congressAdministrator.statistics
    },
  },
  watch: {
    statistics() {
      this.data.statisticsItems[0].title = this.statistics.totalRegistered
      this.data.statisticsItems[1].title = this.statistics.residents
      this.data.statisticsItems[2].title = this.statistics.doctors
      this.data.statisticsItems[3].title = this.statistics.academicStuents
      this.data.statisticsItems[4].title = this.statistics.totalPromotional
      this.data.statisticsItems[5].title = this.statistics.others
      this.data.statisticsItems[6].title = this.statistics.speakers
      this.data.statisticsItems[7].title = this.statistics.fellow
      this.data.statisticsItems[8].title = this.statistics.sbao
      this.data.statisticsItems[9].title = this.statistics.upTo70
      this.data.statisticsItems[10].title = this.statistics.sponsors
      this.data.statisticsItems[11].title = this.statistics.totalCanceled

      this.data.statisticsOrder.series[0].total = this.statistics.totalOrders.total

      this.statistics.totalOrders.months.forEach(element => {
        this.data.statisticsOrder.series[0].data.splice([element.month - 1], 1, element.data)
      })

      this.data.staticAcess.access = this.statistics.access.totalAccess
      this.statistics.access.perDayOfWeek.forEach(element => {
        if (element.dayOfWeek === 4) {
          this.data.staticAcess.series[0].data[element.month - 1] = +element.data
        } else if (element.dayOfWeek === 7) {
          this.data.staticAcess.series[1].data[element.month - 1] = +element.data
        }
      })

      this.data.statisticsProfit.amount = '35.797,79' // this.statistics.value

      this.statistics.totalOrders.months.forEach(element => {
        this.data.statisticsProfit.series[0].data.splice([element.month - 1], 1, element.sumMonth)
      })

      this.data.statisticsItemsTime[0].subtitle = this.statistics.totalTimeWatched
      this.data.statisticsItemsTime[1].subtitle = this.statistics.totalActivities
    },
  },
  mounted() {
    this.ActionGetStatistics({
      slug: this.$route.params.congress
    })
  },
  methods: {
    ...mapActions('congressAdministrator', [
      'ActionGetStatistics'
    ])
  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
