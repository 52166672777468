<template>
  <b-card
    v-if="data"
    body-class="pb-50"
  >
    <h6>Pedidos</h6>
    <h2 class="font-weight-bolder mb-1">
      {{ data.series[0].total }}
    </h2>
    <!-- chart -->
    <b-overlay
      :show="height === 0"
      rounded="sm"
    >
      <vue-apex-charts
        v-if="data.series"
        :height="`${height}%`"
        :options="statisticsOrder.chartOptions"
        :series="dataWatcher"
      />
    </b-overlay>
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import Vue from 'vue'

const $barColor = '#f3f3f3'

export default {
  components: {
    BCard,
    VueApexCharts,
  },
  props: {
    data: {
      type: Object,
      default: () => null,
    }
  },
  data() {
    return {
      height: 0,
      dataWatcher: [0],
      statisticsOrder: {
        chartOptions: {
          chart: {
            type: 'bar',
            stacked: true,
            toolbar: {
              show: false,
            },
          },
          grid: {
            show: false,
            padding: {
              left: 0,
              right: 0,
              top: -15,
              bottom: -15,
            },
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '20%',
              startingShape: 'rounded',
              colors: {
                backgroundBarColors: [$barColor, $barColor, $barColor, $barColor, $barColor],
                backgroundBarRadius: 5,
              },
            },
          },
          legend: {
            show: false,
          },
          dataLabels: {
            enabled: false,
          },
          colors: [$themeColors.warning],

          xaxis: {
            categories: ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'],
            labels: {
              show: true,
            },
            axisBorder: {
              show: false,
            },
            axisTicks: {
              show: false,
            },
          },
          yaxis: {
            show: false,
          },
          tooltip: {
            x: {
              show: false,
            },
          },
        },
      },
    }
  },
  watch: {
    dataWatcher() {
      Vue.set(this.dataWatcher, this.data.series)
      setTimeout(() => {
        this.height = 70
      }, 1000)
    }
  },
  mounted() {
    this.data.series.forEach(element => {
      Vue.set(this.dataWatcher, 0, element)
    })
  }
}
</script>
